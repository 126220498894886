import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


// This component acts as a bridge between the react-router-dom's useNavigate hook and the rest of the application.
export let navigate = null;

const HelperFunctions = () => {
    const navigateComponent = useNavigate()

    useEffect(() => {
        navigate = navigateComponent
    }, [])

    return null
}

export default HelperFunctions;