import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const NavBar = ({ dark }) => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [hamMenu, setHamMenu] = useState(false);
    const [toggleCommercial, setToggleCommercial] = useState(false);
    const [toggleResidential, setToggleResidential] = useState(false);

    useEffect(() => {
        if (hamMenu) {
            // change meta theme color to black
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#4590D5');


        } else {
            // change meta theme color to white
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff');

        }
    }, [hamMenu]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {

            setIsScrolled(window.scrollY > 50);


        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`navbar no-js-scrolled ${isScrolled || dark ? 'scrolled' : ''}`}>
                <div className="nav-left">
                    <Link to="/">
                        <img className="nav-logo" src={
                            isScrolled || dark ? "/assets/FlybyShine-Horizontal-Black.png" : "/assets/FlybyShine-Horizontal-White.png"
                        } alt="logo" />

                        <img className="nav-logo-mobile" src={
                            "/assets/FlybyShine-Horizontal-Black.png"
                        } alt="logo" />
                    </Link>
                    <ul className="nav-items desktop-menu">
                        <li>
                            <Link to="/">Home</Link>
                        </li>

                        <li>
                            <div className="hover-dropdown">
                                <Link to="/commercial-industrial">Commercial & Industrial
                                    <i className="material-icons-round">arrow_drop_down</i>
                                </Link>
                                <div className="dropdown-content vivify fadeIn duration-200">
                                    <div className="dropdown-grouper">
                                        <h4>       <i className="material-icons-round">store</i>COMMERCIAL</h4>
                                        <Link to="/drone-softwash-pressurewash">Drone Soft Wash/Pressure Wash</Link>
                                        <Link to="/dumpster-pad-cleaning">Dumpster Pad Cleaning</Link>
                                        <Link to="/multi-story-window-cleaning">Multi-Story Window Cleaning</Link>
                                        <Link to="/parking-lot-cleaning">Parking Lot Cleaning</Link>

                                        <Link to="/window-cleaning">Window Cleaning </Link>
                                    </div>
                                    <div className="dropdown-grouper">
                                        <h4>
                                            <i className="material-icons-round">construction</i>
                                            INDUSTRIAL</h4>
                                        <Link to="/industrial-cleaning">Industrial Cleaning</Link>
                                        <Link to="/monument-restoration">Monument Restoration</Link>
                                        <Link to="/oil-terminal-tank-exterior-cleaning">Oil Terminal Tank Exterior Cleaning</Link>
                                        <Link to="/stadium-and-dome-cleaning">Stadium and Dome Cleaning</Link>
                                        <Link to="/water-tower-cleaning">Water Tower Cleaning</Link>
                                    </div>

                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="hover-dropdown 0">
                                <Link to="/residential-home">
                                    Residential & Home
                                    <i className="material-icons-round">arrow_drop_down</i>
                                </Link>
                                <div className="dropdown-content vivify fadeIn duration-200">
                                    <div className="dropdown-grouper">
                                        <h4>
                                            <i className="material-icons-round">window</i>
                                            RESIDENTIAL & HOME</h4>

                                        <Link to="/drone-softwash-pressurewash-residential">Drone Soft Wash/Pressure Wash</Link>
                                        <Link to="/residential-softwash-pressurewash">Residential Soft Wash & Pressure Wash</Link>
                                        <Link to="/roof-cleaning">Roof Cleaning</Link>
                                        <Link to="/gutter-cleaning">Gutter Cleaning</Link>
                                    </div>


                                </div>
                            </div>
                        </li>
                        <li>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/why-drones">Why Drones?</Link>
                        </li>
                    </ul>
                </div>

                <div className="mobile-menu vivify fadeInBottom duration-300" style={{
                    display: hamMenu ? 'block' : 'none'
                }}>
                    <ul className="nav-items">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li>
                            <div className="hover-dropdown">
                                <Link onClick={
                                    () => {
                                        setToggleCommercial(!toggleCommercial);
                                        setToggleResidential(false);
                                    }
                                }>Commercial & Industrial
                                    {
                                        toggleCommercial ? <i className="material-icons-round">arrow_drop_up</i> : <i className="material-icons-round">arrow_drop_down</i>
                                    }
                                </Link>
                                {
                                    toggleCommercial ? <div className="dropdown-content">
                                        <div className="dropdown-grouper">
                                            <h4>       <i className="material-icons-round">store</i>COMMERCIAL</h4>
                                            <Link to="/drone-softwash-pressurewash">Drone Soft Wash/Pressure Wash</Link>
                                            <Link to="/dumpster-pad-cleaning">Dumpster Pad Cleaning</Link>
                                            <Link to="/multi-story-window-cleaning">Multi-Story Window Cleaning</Link>
                                            <Link to="/parking-lot-cleaning">Parking Lot Cleaning</Link>

                                            <Link to="/window-cleaning">Window Cleaning </Link>
                                        </div>
                                        <div className="grouper-divider">
                                        </div>
                                        <div className="dropdown-grouper">
                                            <h4>
                                                <i className="material-icons-round">construction</i>
                                                INDUSTRIAL</h4>
                                            <Link to="/industrial-cleaning">Industrial Cleaning</Link>
                                            <Link to="/monument-restoration">Monument Restoration</Link>
                                            <Link to="/oil-terminal-tank-exterior-cleaning">Oil Terminal Tank Exterior Cleaning</Link>
                                            <Link to="/stadium-and-dome-cleaning">Stadium and Dome Cleaning</Link>
                                            <Link to="/water-tower-cleaning">Water Tower Cleaning</Link>
                                        </div>

                                    </div> : null
                                }
                            </div>
                        </li>
                        <li>
                            <div className="hover-dropdown">
                                <Link onClick={
                                    () => {
                                        setToggleResidential(!toggleResidential);
                                        setToggleCommercial(false);
                                    }
                                } >
                                    Residential & Home
                                    {
                                        toggleResidential ? <i className="material-icons-round">arrow_drop_up</i> : <i className="material-icons-round">arrow_drop_down</i>
                                    }
                                </Link>
                                {
                                    toggleResidential ? <div className="dropdown-content">
                                        <div className="dropdown-grouper">
                                            <h4>
                                                <i className="material-icons-round">window</i>
                                                RESIDENTIAL & HOME</h4>

                                            <Link to="/drone-softwash-pressurewash-residential">Drone Soft Wash/Pressure Wash</Link>
                                            <Link to="/residential-softwash-pressurewash">Residential Soft Wash & Pressure Wash</Link>
                                            <Link to="/roof-cleaning">Roof Cleaning</Link>
                                            <Link to="/gutter-cleaning">Gutter Cleaning</Link>
                                        </div>


                                    </div> : null
                                }
                            </div>
                        </li>
                        <li>
                            <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/why-drones">Why Drones?</Link>
                        </li>
                    </ul>
                </div>
            </nav >

            {
                dark ? <div className="nav-spacer"></div> : null
            }
            <div className="mobile-btn">
                <button onClick={
                    () => {
                        setHamMenu(!hamMenu);
                    }
                } className={
                    hamMenu ? 'ham-btn active-btn' : 'ham-btn'
                }>
                    <i className="material-icons-round">{
                        hamMenu ? 'close' : 'menu'
                    }</i>
                </button>
            </div>
        </>
    )
}

export default NavBar